import React from "react"
import PropTypes from "prop-types"

function Hamburger({
  openHamburger,
  isHamburgerOpen,
  classList,
  hamburgerRef,
}) {
  return (
    <div
      role="button"
      tabIndex={0}
      className={`${classList} block cursor-pointer pb-30px pl-30px relative outline-none`}
      onClick={openHamburger}
      onKeyDown={openHamburger}
      ref={hamburgerRef}
      aria-label="button"
    >
      <div className="absolute inset-0 flex items-center justify-center">
        <div>
          <div
            className={`${
              isHamburgerOpen
                ? "hamburger-line -rotate-45 translate-y-full"
                : "hamburger-line rotate-0 translate-y-0"
            }`}
          />
          <div
            className={`${
              isHamburgerOpen
                ? "hamburger-line translate-x-full my-0 opacity-0"
                : "hamburger-line translate-x-0 my-1 opacity-1"
            }`}
          />
          <div
            className={`${
              isHamburgerOpen
                ? "hamburger-line rotate-45 -translate-y-full"
                : "hamburger-line rotate-0 translate-y-0"
            }`}
          />
        </div>
      </div>
    </div>
  )
}

Hamburger.propTypes = {
  openHamburger: PropTypes.func,
  isHamburgerOpen: PropTypes.bool,
}

Hamburger.defaultProps = {
  openHamburger: () => {},
  isHamburgerOpen: false,
}

export default Hamburger
